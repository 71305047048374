import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {QuestionContextProvider} from './store/QuestionContext';
import './styles/index.scss';
import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
import App from './App';

ReactDOM.render(
  <React.StrictMode>    
    <QuestionContextProvider>
      <Router>
        <App />
      </Router>
    </QuestionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
