import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
import mouseClick from "../assets/mouse_click.mp3";
import clickSound from "../assets/click_sound.mp3";
import '../styles/Home.scss';

function Home(props) {

    const [playMouseClick] = useSound(mouseClick);
    const [playClickSound] = useSound(clickSound);

    return (
        < Fragment >
            <Helmet><title>Home - Quiz App</title></Helmet>
            <div id="home">
                <section>
                    <div style={{ textAlign: 'center' }}>
                        <span className="mdi mdi-cube-outline cube"></span>
                    </div>
                    <h1>Quiz App</h1>
                    <div className="play-button-container">
                        <ul>
                            {/* <li><Link className="play-button" to="/play" onClick={playMouseClick}>Play</Link></li> */}
                            <li><Link className="play-button" to="/selectquestions" onMouseEnter={playClickSound} onClick={playMouseClick}>Play</Link></li>
                        </ul>
                    </div>
                    {/* <div className="auth-container">
                        <Link to="/login" className="auth-buttons" id="login-button">Login</Link>
                        <Link to="/register" className="auth-buttons" id="signup-button">Sign up</Link>
                    </div> */}
                </section>
            </div>
        </Fragment >
    )
};

export default Home;